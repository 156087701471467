<div style="background-color: #f1f1f1;">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content before" [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-lg" style="display: flex; justify-content: center; flex-direction: column;">
                    <span class="p-title mt-5">{{'test-title' | translate}}</span>
                    <span class="p-subtitle mb-5" [innerHTML]="'test-sub' | translate"></span>
                  </div>
                  <div class="col-lg">
                        <div class="form">
                            <span class="f-title">{{'test-speak' | translate}}</span>
                            <span class="f-subtitle">{{'test-req' | translate}}</span>

                            <div class="w-100 row mt-2 gap-3" style="padding-left: 10px;">
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">{{'Nome' | translate}}</span>
                                    <input type="text" [placeholder]="'Nome' | translate" class="inputq" maxlength="60" formControlName="nome">
                                </div>
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">E-Mail</span>
                                    <input type="text" placeholder="E-Mail" class="inputq" maxlength="60" formControlName="email">
                                </div>
                            </div>

                            <div class="w-100 row gap-3" style="padding-left: 10px;">
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">{{'test-phone' | translate}}</span>
                                    <input type="text" [placeholder]="'test-phone' | translate" class="inputq" mask="(00) 00000-0009" formControlName="telefone">
                                </div>
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">{{'test-company' | translate}}</span>
                                    <input type="text" [placeholder]="'test-company' | translate" class="inputq" maxlength="60" formControlName="empresa">
                                </div>
                            </div>

                            <span *ngIf="error" class="error">{{error}}</span>

                            <div class="d-flex mt-4 mb-3">
                                <input class="checkbox" type="checkbox" id="terms" name="terms" formControlName="termos" />
                                <label for="terms">{{"test-read" | translate}} <a [routerLink]="['/termos-e-condicoes']">{{"test-terms" | translate}}</a></label>
                            </div>

                            <div class="d-flex" style="margin-bottom: 30px;">
                                <input class="checkbox" type="checkbox" id="termsB" name="termsB" formControlName="termosB" />
                                <label for="termsB">{{"test-read" | translate}} <a href="https://app.lgpd.center/cyber/portal/QualityManager#privacidade" target="_blank">{{"test-policy" | translate}}</a></label>
                            </div>

                            <button (click)="sendForm()" class="button w-100">{{"test-send" | translate}}</button>
                        </div>
                  </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>