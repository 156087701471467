<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container xs-10">
            <div class="lgpd-section-title">
                <h2 class="">License Agreement for Use and Service Provision of QualityManager® - Rev. 4.0</h2>
            </div>
            <div class="row d-flex flex-center">

                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <p class="text-justify">These are the terms of the license agreement and service provision that
                            govern the use of the online system QualityManager®, also referred to as QualityManager®.<br>
                            By using QualityManager®, you fully agree to these terms. If you are using it on behalf of a
                            company, you declare that the company also agrees with these terms. <br>
                            If you do not fully agree with the terms, do not use QualityManager®.</p>
                        <p class="text-justify"><strong>Licensor: </strong>Cyber1 do Brasil Ltda, established in 2000,
                            headquartered at Rua Nossa Senhora da Lapa, 671 – Cj 11, São Paulo/SP, ZIP Code: 05072-900,
                            registered under CNPJ 04.019.962/00001-43, exempt from state registration, hereinafter
                            referred to as Licensor.</p>
                        <p class="text-justify"><strong>Licensee: </strong>A legal entity acquiring this license for the
                            use of QualityManager®, identified through acceptance of the terms below and with an
                            agreement formalized by the Licensor's responsible department, hereinafter referred to as
                            Licensee.</p>

                        <p class="text-justify">The parties identified above have agreed upon this Non-Exclusive License
                            Agreement for the Use and Service Provision of the online software QualityManager®, governed
                            by the following clauses and conditions described herein.</p>
                        <hr>

                        <p class="text-justify"><strong>Object: </strong></p>
                        <p class="text-justify"><strong>Clause 1 – </strong>The QualityManager® quality management software, also referred to as QualityManager®, is an online software developed and owned by the Licensor, with the purpose of managing, creating, and storing all the data required during the Advanced Product Quality Planning (APQP).</p>
                        <p class="text-justify"><strong>Clause 2 – </strong>QualityManager® is a registered trademark at INPI, the National Institute of Industrial Property, under number 901307823.</p>
                        <p class="text-justify"><strong>Clause 3 – </strong>QualityManager® is made available by the Licensor for use by the Licensee via the internet through a secure website connection.</p>
                        <p class="text-justify"><strong>Clause 4 – </strong>QualityManager® may also be referred to as a web solution, computer program, software, system, or website, according to the object defined in Clause 1.</p>


                        <p class="text-justify"><strong>License of Use: </strong></p>
<p class="text-justify"><strong>Clause 5 – </strong>This license of use for QualityManager® shall have the aspects of irreversibility and irrevocability.</p>
<p class="text-justify"><strong>Clause 6 – </strong>This term of QualityManager® represents proof of the License of Use and must be treated as valuable property.</p>
<p class="text-justify"><strong>Clause 7 – </strong>The Licensor, owner of all rights to QualityManager® under this contract, hereby grants the Licensee the License of Use, on a non-exclusive and non-transferable basis, for the software, the object of this License of Use, made available for use via the internet, in accordance with the terms set forth in this License of Use.</p>
<p class="text-justify"><strong>Clause 8 – </strong>The acceptance of this term is confirmed when using the software, even partially, as a test or when acquiring this License of Use for QualityManager®, done electronically through a form on the website, by the specific action of submitting the form and accepting the terms specified in the form.</p>
<p class="text-justify"><strong>Clause 9 – </strong>The use of QualityManager®, even partially or as a test, indicates that the Licensee is aware of the terms of the License of Use for QualityManager®, agreeing to all of them.</p>
<p class="text-justify"><strong>Clause 10 – </strong>If there is disagreement with the terms presented here, the use of QualityManager® must be immediately stopped.</p>

<p class="text-justify"><strong>Updates: </strong></p>
<p class="text-justify"><strong>Clause 11 – </strong>The Licensor guarantees, at no additional cost to the Licensee, the update of the system whenever there are modifications to the requirements of the IATF 16949:2016 standard and the reference manuals (MSA, SPC, FMEA, APQP, and PPAP).</p>


<p class="text-justify"><strong>Obligations of the Licensor: </strong></p>
<p class="text-justify"><strong>Clause 12 –</strong> Weekly backup of the MySQL database with monthly retention.</p>
<p class="text-justify"><strong>Clause 13 –</strong> Secure connection between the user and the QualityManager® application.</p>
<p class="text-justify"><strong>Clause 14 –</strong> For the Licensee to use QualityManager®, it is necessary that it is properly installed and configured on an internet server by the Licensor.</p>
<p class="text-justify"><strong>Clause 15 –</strong> Maintain an SLA of 99.1% of online service per year.</p>

<p class="text-justify"><strong>Obligations of the Licensee: </strong></p>
<p class="text-justify"><strong>Clause 16 –</strong> The Licensee is fully responsible for the information entered into the system, for registration, permissions, passwords, and the usage of its users.</p>
<p class="text-justify"><strong>Clause 17 –</strong> The Licensee assumes the responsibility of hiring employees with technical knowledge of the IATF 16949:2016 standard and the reference manuals (MSA, SPC, FMEA, APQP, and PPAP).</p>
<p class="text-justify"><strong>Clause 18 –</strong> The Licensee must keep their employees always updated on quality tools so that they can use the QualityManager® solution more efficiently and effectively.</p>
<p class="text-justify"><strong>Clause 19 –</strong> The responsibility for each user's information in QualityManager® lies with the Licensee.</p>
<p class="text-justify"><strong>Clause 20 –</strong> The Licensee must pay the Licensor an amount for the license of use of QualityManager®.</p>

<p class="text-justify"><strong>Types of Licenses: </strong></p>
<p class="text-justify"><strong>Clause 21 –</strong> QualityManager® offers four types of licenses: Startup, Business, Premium, and Enterprise. The features of each are available at the following link: <a href="https://qualitymanager.com.br/compare/" target="_blank" style="font-size:14px">https://qualitymanager.com.br/compare/</a></p>


<p class="text-justify"><strong>Commercial Conditions: </strong></p>
<p class="text-justify"><strong>Clause 22 – </strong>No fee is charged for the use of QualityManager® with the Startup license, this version is completely free.</p>
<p class="text-justify"><strong>Clause 23 – </strong>The price for online access to QualityManager® will be defined according to the number of concurrent access licenses acquired and the plan chosen.</p>
<p class="text-justify"><strong>Clause 24 – </strong>The values for services such as certification, installations, configurations, maintenance, improvement requests, and other services requested by the Licensee in the future will be previously budgeted and communicated to the Licensee through a new commercial proposal. New services will only be executed upon approval of their respective proposals.</p>
<p class="text-justify"><strong>Clause 25 – </strong>In case of non-payment of the amounts related to the subject of this contract, the Licensee is subject to temporary interruption or permanent cancellation of the services provided by the Licensor.</p>

<p class="text-justify"><strong>Rules for Content Publication: </strong></p>
<p class="text-justify"><strong>Clause 26 – </strong>The following rules must be followed by the Licensee, aiming for moral and social respect, as well as the good and proper functioning of the QualityManager® system (software or site). The inclusion of the following content is expressly prohibited:<br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Publication or dissemination of pornographic, racist, or offensive material; <br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Incitement to crime or information about illegal activities; <br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Pornographic material or illegal activities, including minors under 18 (eighteen) years old; <br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Defamatory material that falsely attributes a criminal act to someone, offensive statements that harm someone's dignity or decorum, as well as defamatory statements that damage someone's reputation; <br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Information related to software piracy; <br>
<i class="fa fa-check" style="padding-left: 5%;"></i>Material protected by copyright, prohibiting the publication of photos, texts, or audio files without the authorization of the work's representative or responsible company. <br>
</p>
<p class="text-justify" style="padding-left: 20%;"><strong>Paragraph – </strong>The Licensor or their Representatives will in no case be responsible for the content (information, passwords, copies of information, etc.) of any kind published in the software, and these pieces of information are not reviewed or disclosed at any time.</p>


<p class="text-justify"><strong>Clause 27 – </strong>It is expressly prohibited:<br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>To use QualityManager® outside the conditions established in this License of Use; <br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>To translate, reverse engineer, decompile, copy images, codes, or any part of QualityManager® for use outside of it; <br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>To rent, lease, assign, or transfer the licensed product; <br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>To modify the product or merge all or any part of it with another program; <br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>To remove or alter any copyright, trademark, or other property rights notice placed on QualityManager® or any part of it. <br>
    </p>
    
    <p class="text-justify"><strong>Technical Support: </strong></p>
    <p class="text-justify"><strong>Clause 28 – </strong>The technical support service conditions will be in accordance with the plan contracted at the time of service acquisition.</p>
    <p class="text-justify"><strong>Clause 29 – </strong>Technical support may be via email, ticketing system, or phone, and may vary according to the plan contracted at the time of service acquisition.</p>
    <p class="text-justify"><strong>Clause 30 – </strong>Technical support is provided only during business hours for the use of resources available in QualityManager®.</p>
    <p class="text-justify"><strong>Clause 31 – </strong>Business hours are considered to be from 9 AM to 12 PM and from 2 PM to 5 PM, Monday through Friday, excluding state and municipal holidays in São Paulo and national holidays.</p>
    <p class="text-justify"><strong>Clause 32 – </strong>The response time for technical tickets is measured in business hours, and the response time may vary according to the plan contracted at the time of service acquisition.</p>
    <p class="text-justify"><strong>Clause 33 – </strong>Technical support provided to the Licensee is limited to clarifications regarding the operation of QualityManager® and its functionality. It assumes the Licensee has basic knowledge of computer usage and quality tools, including computer use and its functions, the operating system under which the system will run, and the subject that QualityManager® aims to address. Support channels and response times may vary depending on the contracted plan.</p>
    <p class="text-justify"><strong>Clause 34 – </strong>It is also assumed that the computer is properly configured for using the acquired software and that the computer is in good working condition and no more than 3 (three) years old.</p>
    <p class="text-justify"><strong>Clause 35 – </strong>QualityManager® is certified <strong>only</strong> for use with the GOOGLE CHROME internet browser (Browser).</p>
    <p class="text-justify"><strong>Clause 36 – </strong>The technical support provided by the Licensor does not cover internet connection, internal network, or computers of the Licensee.</p>
    

    <p class="text-justify"><strong>Customizations: </strong></p>
    <p class="text-justify"><strong>Clause 37 – </strong>If the Licensee requires modifications to QualityManager® to better meet their needs, the procedure to be followed is described below: <br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>The Licensee contacts the Licensor and expressly informs what they need;<br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>The Licensor analyzes the Licensee's request regarding its technical feasibility and sends a commercial proposal with the cost and estimated completion time of the requested services;<br>
    <i class="fa fa-check" style="padding-left: 5%;"></i>After the proposal is approved by the Licensee, the Licensor begins the services and provides the modifications in a testing environment—another environment with a copy of QualityManager® running—so that the Licensee can perform tests and validate the new implementations, after which they will be incorporated into the production version of the system.<br>
    </p>
    
    <p class="text-justify"><strong>Clause 38 –</strong> All modifications, improvements, and corrections made to QualityManager®, even if informed, requested, and possibly paid for by the Licensee, become part of QualityManager® and are subject to the terms of this License of Use of QualityManager®. They may also be made available by the Licensor to other Licensees using the same system model, and the Licensee may eventually receive performance improvements, corrections, and new features in the software they use.</p>
    
    <p class="text-justify"><strong>Clause 39 –</strong> The Licensee hereby agrees to modifications, implementation of new features, or tools, improvements, or corrections in the QualityManager® model they use, with the Licensor having the discretion to manage and approve these modifications to QualityManager®.</p>
    
    <p class="text-justify"><strong>Clause 40 –</strong> Updates will be automatically installed by the Licensor in QualityManager®.</p>
    
    <p class="text-justify"><strong>Term of validity: </strong></p>
    <p class="text-justify"><strong>Clause 41 – </strong>The term for using QualityManager® is indefinite, meaning the Licensee can use QualityManager® for as long as they wish, provided they remain compliant with the payment of their license while using QualityManager®.</p>
    
    <p class="text-justify"><strong>Termination: </strong></p>
    <p class="text-justify"><strong>Clause 42 – </strong>The Licensee may terminate this agreement at any time, provided they inform the Licensor in writing with 30 days' notice.</p>
    <p class="text-justify"><strong>Clause 43 – </strong>Under no circumstances will any payments be refunded.</p>
    <p class="text-justify"><strong>Clause 44 – </strong>The Licensor may terminate this agreement in the event of the Licensee's failure to fulfill their contractual obligations.</p>
    <p class="text-justify"><strong>Clause 45 – </strong>The Licensee's database will be available for download, if requested, during the 30-day notice period.</p>
    


    <p class="text-justify"><strong>General Conditions: </strong></p>
    <p class="text-justify"><strong>Clause 46 –</strong> It is also established that the Licensor will always use its best efforts in maintaining and advancing the technology of QualityManager® and the server hosting it.</p>
    <p class="text-justify"><strong>Clause 47 –</strong> Under no circumstances shall the Licensor be responsible for lost profits, indemnities of any kind, commercial damages, special, incidental, consequential or other direct or indirect damages.</p>
    <p class="text-justify"><strong>Clause 48 –</strong> The use of QualityManager® is subject to the limitations offered according to the plan contracted.</p>
    <p class="text-justify"><strong>Clause 49 –</strong> The Licensor reserves the right to modify this agreement as necessary, with the purpose of preserving its intellectual property and ensuring the proper functioning of the system.</p>
    <p class="text-justify"><strong>Clause 50 –</strong> The Licensor may, at any time, suspend or remove the Licensee's access if there is a publication or information that violates the content rules set forth here or any applicable legal norms, without any refund of payments made by the Licensee.</p>
    <p class="text-justify"><strong>Clause 51 –</strong> Under no circumstances shall the Licensee have access to the source code of the licensed system, as it is the intellectual property of the Licensor.</p>
    <p class="text-justify"><strong>Clause 52 –</strong> This agreement does not include on-site training or technical visits; if needed, your company must request a specific proposal.</p>
    <p class="text-justify"><strong>Clause 53 –</strong> The database storage space is unlimited, but the disk space for uploading external files is limited to 500MB per license.</p>
    <p class="text-justify"><strong>Clause 54 –</strong> Daily, after 10:00 PM, and during weekends and holidays, the services may be interrupted for maintenance, updates, backups, and other security procedures.</p>
    
    <p class="text-justify"><strong>Confidentiality Agreement: </strong></p>
    <p class="text-justify"><strong>Clause 55 –</strong> The parties agree to keep confidential all technical and non-technical information related to the solution, its equipment, infrastructure, its use, system access passwords, third-party service connection passwords, and other information necessary for the integrated operation of the QualityManager® solution, the subject of this agreement.</p>
    <p class="text-justify"><strong>Clause 56 –</strong> Neither party shall provide information to third parties or allow third parties to access any information they administer, access, and/or control related to the QualityManager® solution.</p>
    <p class="text-justify"><strong>Clause 57 –</strong> The parties are obligated to maintain the utmost secrecy and confidentiality regarding the solution, the requested and executed services, documentation, and all verbal or written, recorded, and private information, business secrets, or any other information they have access to during the term of this agreement or after its termination, both in the workplace and in the premises of any client indicated, and cannot use or disclose such information to any third party or stranger to this agreement.</p>
    <p class="text-justify"><strong>Clause 58 –</strong> The parties expressly acknowledge that all documents of any nature that have been used, created, or are under their control will also be safeguarded by the commitment to confidentiality.</p>
    <p class="text-justify"><strong>Clause 59 –</strong> The parties expressly declare that they understand that confidentiality and secrecy are essential to the commercial/technical relationship between them.</p>
    <p class="text-justify"><strong>Clause 60 –</strong> Any document, information, or part related to QualityManager® services shall not be disclosed to third parties without formal authorization from the party holding the rights to it.</p>
    <p class="text-justify"><strong>Clause 61 –</strong> Each party is entirely responsible for the internal confidentiality of the information contained in any document or part of the system, being liable for the misuse of information arising from this agreement by their employees or third parties appointed by them.</p>
    <p class="text-justify"><strong>Clause 62 –</strong> No part of the solution may be used in other solutions or systems that the parties may create or have.</p>
    <p class="text-justify"><strong>Clause 63 –</strong> Even after the termination of this Agreement, the parties are committed to keeping all information entrusted to them confidential.</p>
    


    <p class="text-justify"><strong>Billing and Collection: </strong></p>
    <p class="text-justify"><strong>Clause 64 –</strong> The company DESENVAZ SOLUÇÕES EM TECNOLOGIA, registered under CNPJ number 50.219.996/0001-07, hereinafter referred to as "Billing and Collection Company", will be responsible for all activities related to the billing and collection of services provided by the Licensor to the Licensee, as established in this agreement.</p>
    <p class="text-justify"><strong>Clause 65 –</strong> The Billing and Collection Company will act on behalf of the Licensor to carry out the necessary collections and ensure the receipt of the amounts due for the services provided.</p>
    <p class="text-justify"><strong>Clause 66 –</strong> For the payment of services rendered, the Licensee must make payments directly to the Billing and Collection Company using the following bank details: Bank: 655 – BCO VOTORANTIM S.A., Branch: 1111, Account: 62262551479</p>
    <p class="text-justify" style="padding-left: 5%;"><strong>Single Clause –</strong> The Collection Company provides the option of payment via PIX, using CNPJ 50.219.996/0001-07.</p>
    <p class="text-justify"><strong>Clause 67 –</strong> The Licensee agrees to make payments within the deadlines established by the Billing and Collection Company and assumes full responsibility for fulfilling the financial obligations as agreed in this contract.</p>
    <p class="text-justify"><strong>Clause 68 –</strong> Any communication related to collections, billing, or payments must be made directly with the Collection Company using the contact channels provided by them.</p>
    <p class="text-justify"><strong>Clause 69 –</strong> The Licensor and the Licensee agree that the Billing and Collection Company is a third party involved in this agreement, acting independently and without any employment or corporate relationship with either party.</p>
    <p class="text-justify"><strong>Clause 70 –</strong> Both parties acknowledge that the Billing and Collection Company has full autonomy to manage the collection, billing, and receipt of amounts due for services rendered, and the Licensor is not directly responsible for any failures or delays in the activities carried out by the Collection Company.</p>
    <p class="text-justify"><strong>Clause 71 –</strong> Any disputes or disagreements related to collections, billing, or payments should be resolved directly between the Licensee and the Billing and Collection Company, without involving the Licensor, except in cases where the Licensor is directly involved in specific contractual matters.</p>
    
    <p class="text-justify"><strong>Adjustment: </strong></p>
    <p class="text-justify"><strong>Clause 72 –</strong> This agreement establishes that the monthly fee for the provided service will be adjusted annually based on the General Price Index of the Market (IGP-M), published by the Fundação Getúlio Vargas (FGV).</p>
    <p class="text-justify"><strong>Clause 73 –</strong> The IGP-M will be used as a reference for calculating the annual adjustment, in order to keep the contract value aligned with the average variation of prices in the market.</p>
    <p class="text-justify"><strong>Clause 74 –</strong> The annual adjustment will be applied based on the date of access release to the system, considering the accumulated variation of the IGP-M for the period between the last adjustment and the current date.</p>
    <p class="text-justify"><strong>Clause 75 –</strong> The accumulated variation of the IGP-M is the accumulated percentage variation of the IGP-M since the last adjustment. The accumulated variation of the IGP-M will be obtained based on the data published by FGV or the National Consumer Price Index (IPCA), published by the Brazilian Institute of Geography and Statistics (IBGE).</p>
    <p class="text-justify"><strong>Clause 76 –</strong> The parties agree that the annual adjustment based on the IGP-M is a fair and reasonable practice to maintain the economic-financial balance of the contract.</p>
    


    <p class="text-justify"><strong>Final Provisions: </strong></p>
    <p class="text-justify"><strong>Clause 77 –</strong> This agreement does not bind any of the parties to additional obligations, nor grants additional rights, except for those expressly provided in this instrument, or those arising from its text.</p>
    <p class="text-justify"><strong>Clause 78 –</strong> The Licensor may use the Licensee's brand and create cases to disclose and communicate the services contracted in this agreement for marketing and media purposes.</p>
    <p class="text-justify"><strong>Clause 79 –</strong> It is agreed that the Licensee may not hire any employee, service provider, legal entity, or even any person with technical relations with the Licensor without the Licensor's mediation. If this occurs within 700 days after the termination of the employee's contract with the Licensor, the Licensee will be required to pay the Licensor an amount equivalent to 100 minimum wages, according to the respective union.</p>
    <p class="text-justify"><strong>Clause 80 –</strong> The Licensor does not have a Data Protection Impact Assessment (DPIA), as the collected data is not sensitive personal data and does not result in a high risk to the rights and freedoms of individuals.</p>
    <p class="text-justify"><strong>Clause 81 –</strong> In compliance with the LGPD (General Data Protection Law), the Licensor provides a communication channel within the application for the Licensee's users and collaborators to make requests regarding their personal data.</p>
    <p class="text-justify"><strong>Clause 82 –</strong> Omitted cases will be resolved by mutual agreement through a meeting of the parties for this purpose. An amendment to this contract must be prepared and signed by both parties.</p>
    
    <p class="text-justify"><strong>Jurisdiction: </strong></p>
    <p class="text-justify"><strong>Clause 83 –</strong> The parties hereby elect, with the exclusion of any other jurisdiction, regardless of how privileged it may be, the Central Court of the City of São Paulo – SP, to settle any doubts or disputes arising from this agreement.</p>
    
    <hr>
    <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
            <p class="text-right">
                <strong>Revision History</strong><br><strong>Rev. 1.0 </strong> - 11-05-22 - Alteration of the Licensor's data, including the company name and CNPJ.<br>
                <strong>Rev. 2.0 </strong> - 24-05-22 - General revision of the terms of use.<br>
                <strong>Rev. 3.0 </strong> - 12-05-23 - General revision of the terms of use and renewal.<br>
                <strong>Rev. 4.0 </strong> - 01-07-23 - General revision of the terms of use, billing, and adjustment.
            </p>
        </div>
    </div>
    



                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>